import * as React from 'react';
import { useEffect } from "react";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import { IState } from '../../redux/@types';
import licensesApi from '../../redux/apis/licensesApi';


interface StoreSuccessProps extends RouteComponentProps {}

const StoreSuccess: React.FC<StoreSuccessProps> = () => {
    const dispatch = useDispatch();

    const pathname = typeof window !== "undefined" && window.location.pathname;
    const routes = pathname && pathname.split('/') || [];
    const sessionId = routes[routes.length-1];

    const constants = useSelector((state: IState) => state.constants);
    const apiGatewayUrl = constants.apiGatewayUrl;
    const paymentApiKey = constants.paymentApiKey;

    const confirmPurchase = async (sessionId: string) => {
        console.log("navigate here", sessionId);
        if (sessionId && apiGatewayUrl && paymentApiKey) await licensesApi.checkoutFulfil({ apiGatewayUrl, paymentApiKey, sessionId });
        navigate('/app/store');
    }

    useEffect(() => {
        confirmPurchase(sessionId);
    }, [sessionId]);
    
    navigate('/app/store');

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            top: '50%',
            left: '50%',
            display: 'flex',
            alignItems: 'center',
        }}>
            <Typography variant="h6">Processing</Typography>
            <CircularProgress size="24px" sx={{ marginLeft: '4px' }} />
        </Box>
    );
}

export default StoreSuccess;