import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import StoreIndex from './StoreIndex';
import StoreSuccess from './StoreSuccess';


const Store: React.FC<RouteComponentProps> = () => (
    <Router>
        <StoreIndex default path="/" />
        <StoreSuccess path="/success/:sessionId" />
        {/* <StoreCancel default path="/cancel" /> */}
    </Router>
);

export default Store;