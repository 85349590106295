import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportsActionsAsync } from '../../redux/actions/reportsActions';
import { IState } from '../../redux/@types';
import { analysesActionsAsync } from '../../redux/actions/analysesActions';
import { formsActions } from "../../redux/actions/formsActions";
import { navigate } from "@reach/router";


export const useDirectLink = () => {
    const dispatch = useDispatch();

    const analyses = useSelector((state: IState) => state.analyses.analyses);
    const reports = useSelector((state: IState) => state.reports.reports);
    const classifications = useSelector((state: IState) => state.forms.classifications);

    const downloadReport = (reportId: string) => {
        if (analyses.length < 1 || reports.length < 1 || Object.keys(classifications).length < 1) return;
        if (reportId) dispatch(reportsActionsAsync.downloadReport.request({ reportId }));
        sessionStorage.removeItem("downloadReport");
        // Also display dashboard report view if AHVVA
        let report;
        let analysis;
        for (let i = 0; i < reports.length; i++) {
            if (reports[i].report_id === reportId) {
                report = reports[i];
                break;
            }
        }
        if (!report) return;
        for (let i = 0; i < analyses.length; i++) {
            if (analyses[i].analysis_id === report.analysis_id) {
                analysis = analyses[i];
                break;
            }
        }
        if (!analysis) return;
        const classification = classifications[analysis.classification_id];
        if (classification && classification.majorType === 'AHVVA') {
            dispatch(analysesActionsAsync.getAnalysis.request({ analysisId: analysis.analysis_id }));
            dispatch(formsActions.viewAhvva(analysis));
        }
    }
    const goto = (path: string) => {
        sessionStorage.removeItem("redirectStore");
        navigate(path);
    }
    
    useEffect(() => {
        // Download report automatically if direct link used
        const storedReportId = sessionStorage.getItem("downloadReport");
        if (storedReportId) downloadReport(storedReportId);

        // Redirect shop
        const redirectStore = sessionStorage.getItem("redirectStore");
        if (redirectStore) goto(redirectStore);
    }, [])
}