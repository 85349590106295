import { createSelector } from "reselect";
import { IState } from "../@types";


export const classificationCountSelector = (classificationId: string) => createSelector(
    (state: IState) => state.clients.counts,
    (state: IState) => state.users.user,
    (counts, user) => {
        if (!user) return null;
        if (user.role === "super") {
            // return counts.find(count => count.classification_id === classificationId && count.client_id === "");
            const licences = counts.filter(count => count.classification_id === classificationId && count.client_id === "");
            return { remaining: licences.reduce((a, l) => a + l.remaining, 0)}
        } else {
            const licences = counts.filter(count => count.classification_id === classificationId);
            return { remaining: licences.reduce((a, l) => a + l.remaining, 0)}
        }
    }
);