import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import LicencesIndex from './LicencesIndex';


const Licences: React.FC<RouteComponentProps> = () => (
    <Router>
        <LicencesIndex default path="/" />
    </Router>
);

export default Licences;