import * as React from "react";
import { useMemo, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from "../redux/@types";
import {
    Box,
    Drawer,
    CssBaseline,
    Toolbar,
    List,
    Divider,
    ListItem,
    ListSubheader,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import {
    Home as HomeIcon,
    Business as BusinessIcon,
    People as PeopleIcon,
    Article as ArticleIcon,
    Info as InfoIcon,
    Storefront as StorefrontIcon,
} from "@mui/icons-material";
import { clientsActionsAsync } from "../redux/actions/clientsActions";
import { usersActionsAsync } from "../redux/actions/usersActions";
import { analysesActionsAsync } from "../redux/actions/analysesActions";
import { licensesActionsAsync } from "../redux/actions/licensesActions";


interface DrawerLeftProps extends RouteComponentProps {
    width?: number;
}

const DrawerLeft: React.FC<DrawerLeftProps> = ({
    // width = 240,
}) => {
    const dispatch = useDispatch()

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const getClients = () => dispatch(clientsActionsAsync.getClients.request({}));
    const getUsers = () => dispatch(usersActionsAsync.getUsers.request({}));
    const getAnalyses = () => dispatch(analysesActionsAsync.getAnalyses.request({}));
    const getLicenseTypes = () => dispatch(licensesActionsAsync.getLicenseTypes.request({}));
    const getLicences = () => dispatch(clientsActionsAsync.getClientsCounts.request({}));
    const openHelp = () => window && window.open("https://docs.assessthreat.live/docs/", "_blank")?.focus();
    const user = useSelector((state: IState) => state.users.user);
    const showStore = useSelector((state: IState) => state.constants.showStore);

    const subList = (
        subheader: string,
        items: {
            label: string,
            icon?: any,
            path?: any,
            fn?: any,
            title?: string,
        }[]
    ) => {
        return (
            <List>
                <ListSubheader sx={{
                    fontSize: 17,
                    ...(!drawerOpen && { display: 'none' }),
                }}>
                    {subheader}
                </ListSubheader>
                {items.map(item => (
                    <Tooltip key={item.title} title={item.title} placement="right">
                    <ListItem key={item.label}
                        disablePadding
                        onClick={() => {
                            if (item.fn) item.fn()
                            navigate(item.path || "/app")
                        }}
                    >
                        <ListItemButton
                            sx={{ py: 0, minHeight: 32 }}
                        >
                            {item.icon && <ListItemIcon
                                sx={{ minWidth: 40 }}
                            >
                                {item.icon}
                            </ListItemIcon>}
                            <ListItemText
                                sx={{
                                    ...(!drawerOpen && { display: 'none' }),
                                }}
                                primary={item.label}
                                primaryTypographyProps={{ fontSize: 17, fontWeight: 'medium' }}
                            />
                        </ListItemButton>
                    </ListItem>
                    </Tooltip>
                ))}
            </List>
        );
    };
    const managementOptions = useMemo(() => {
        let managementOptions = [];
        if (user) {
            const role = user.role;
            const clientId = user.clientId;
            if (
                role === 'super'
                || (role === 'admin' && !clientId)
            ) managementOptions.push({ title: "Clients", label: "Clients", icon: <BusinessIcon />, path: "/app/clients", fn: getClients });
            if (
                role === 'admin' || role === 'super'
            ) managementOptions.push({ title: "Users", label: "Users", icon: <PeopleIcon />, path: "/app/users", fn: getUsers });
            if (showStore) managementOptions.push({ title: "Licences", label: "Licences", icon: <ArticleIcon />, path: "/app/licences", getLicences });
            if (
                (role === 'admin' || role === 'super') && showStore
            ) managementOptions.push({ title: "Store", label: "Store", icon: <StorefrontIcon />, path: "/app/store", fn: getLicenseTypes });
        }
        return subList("Management", managementOptions);
    }, [user, drawerOpen]);

    return (
        <Box sx={{
            display: 'flex',
        }}>
        <CssBaseline />
        <Drawer
            sx={{
                width: drawerOpen ? '220px' : '60px',
                flexShrink: 0,
                transition: 'width 225ms',
                '& .MuiDrawer-paper': {
                    width: drawerOpen ? '220px' : '60px',
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                },
            }}
            variant="permanent"
            anchor="left"
            open={drawerOpen}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    marginLeft: '-10px'
                }}
                onClick={() => setDrawerOpen(!drawerOpen)}
            >
                <Box sx={{
                    marginTop: '5px',
                    marginLeft: '-7px',
                    'img': {
                        width: '200px',
                        objectPosition: 'left top',
                    },
                }}>
                    <img src="/logo_new.png" />
                </Box>
            </Toolbar>
            <Divider />
            {subList("General", [
                { title: "Overview", label: "Overview", icon: <HomeIcon />, fn: getAnalyses }
            ])}
            <Divider />
            {managementOptions}
            <Divider />
            {subList("Help", [
                { title: "Help", label: "Assess Threat", icon: <InfoIcon />, fn: openHelp },
            ])}
        </Drawer>
      </Box>
    );
}

export default DrawerLeft;