import * as React from 'react';
import { useState, useMemo, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    Divider,
    CircularProgress,
    Backdrop,
} from "@mui/material";
import {
    Euro as EuroIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { licensesActionsAsync } from '../../redux/actions/licensesActions';
import { LicenseType } from '../../redux/@types/licenses';

const YEAR_DAYS = 365;
const PROGRESS_TIMEOUT = 10000; //10s

interface StoreIndexProps extends RouteComponentProps {}

const StoreIndex: React.FC<StoreIndexProps> = () => {
    const dispatch = useDispatch();

    const hash = typeof window !== "undefined" && window.location.hash;

    const licenseTypes = useSelector((state: IState) => state.licenses.licenseTypes);
    const pdsContactUsUrl = useSelector((state: IState) => state.constants.pdsContactUsUrl);
    const pdsClassificationId = useSelector((state: IState) => state.constants.pdsClassificationId);
    const [progress, setProgress] = useState<boolean>(false);

    const getLicenseTypes = async () => {
        dispatch(licensesActionsAsync.getLicenseTypes.request({}));
    }
    const determineCurrencyIcon = (priceCurrency: string) => {
        switch (priceCurrency) {
            case 'GBP':
                return <EuroIcon fontSize='small' />
            default:
                return "$"
        }
    }
    const formatPrice = (price: number) => {
        return price.toLocaleString();
    }
    const purchaseProduct = (licenceType: LicenseType) => {
        setProgress(true);
        setTimeout(() => setProgress(false), PROGRESS_TIMEOUT);
        let expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate()+(licenceType.expiry_length_year*YEAR_DAYS));
        dispatch(licensesActionsAsync.purchaseProduct.request({
            amount: +licenceType.price_amount,
            quantity: 1,
            currency: licenceType.price_currency,
            success_url: `${window.location.origin}/app/store/success`,
            cancel_url: `${window.location.origin}/app/store`,
            type: licenceType.type,
            classification_id: pdsClassificationId,
            no_of_reports: licenceType.no_of_reports,
            ...(licenceType.expiry_length_year && { expiry_date: expiryDate.toISOString() }),
        }));
    }

    const items = useMemo(() => {
        return Object.values(licenseTypes).sort((a,b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }, [licenseTypes]);

    useEffect(() => {
        getLicenseTypes();
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            '& > div:not(:last-child)': {
                marginRight: '20px',
            },
            '& > div': {
                marginBottom: '20px',
            },
            flexWrap: 'wrap',
            marginBottom: '24px',
        }}>
            {items.map(l =>
                <Card key={`card-${l.type}`} sx={{
                    width: '300px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}>
                    <CardContent>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            {l.name}
                        </Typography>
                        <Typography
                            variant="h5"
                            component="div"
                            sx={{ alignItems: 'center', display: 'flex' }}
                        >
                            {l.price_amount && determineCurrencyIcon(l.price_currency)}
                            {l.price_amount && formatPrice(+l.price_amount)}
                            {!l.price_amount && "Contact us"}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{l.description}</Typography>
                        <Divider />
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                            '& > div:not(:last-child)': {
                                marginBottom: '10px',
                            },
                        }}>
                            {l.features_descriptions.map((d,i) => (
                                <Box key={`feature-desc-${l.type}-${i}`}>
                                    <Typography sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <CheckCircleOutlineIcon fontSize="medium" />
                                        {d}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </CardContent>
                    <CardActions sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        {l.price_amount &&
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => purchaseProduct(l)}
                                // disabled={progress}
                            >
                                Buy
                            </Button>
                        }
                        {!l.price_amount &&
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => window.open(pdsContactUsUrl, '_self')}
                            >
                                Contact Us
                            </Button>
                        }
                    </CardActions>
                </Card>
            )}
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={progress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default StoreIndex;