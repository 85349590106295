import * as React from 'react';
import { useMemo, useEffect, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../redux/@types';
import { MAX_AHVVA_PATHS, MIN_REMAINING_DISTANCE } from '../../constants';
import {
    ViewData,
    ComponentSection,
    Component,
} from "../../redux/@types/forms";
import {
    Box,
    Toolbar,
    Container,
    Button,
    Typography,
    Divider,
} from "@mui/material";
import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon,
    ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import FormTitle from "./FormTitle";
import RowText from "./RowText";
import SingleLineInput from "./SingleLineInput";
import NumberInput from "./NumberInput";
import SingleChoiceInput from './SingleChoiceInput';
import MultipleChoiceInput from "./MultipleChoiceInput";
import FreeTextInput from './FreeTextInput';
import Calendar from "./Calendar";
import DrawableMap from './DrawableMap';
import Map from './Map';
import { formsActions, formsActionsAsync } from "../../redux/actions/formsActions";
import { AnalysisResponse } from '../../utils/AnalysisResponse';
import { AnalysisResponseTERA } from '../../utils/AnalysisResponseTERA';
import { parseFormula } from '../../utils/parseFormula';
import EmailListInput from './EmailListInput';
import EmailSingleInput from './EmailSingleInput';
import { checkImperial } from '../../utils/checkImperial';


interface FormContentProps extends RouteComponentProps {}

const FormContent: React.FC<FormContentProps> = () => {
    const dispatch = useDispatch();

    const classificationId = useSelector((state: IState) => state.forms.currentAnalysis?.classification_id);
    const classification = classificationId && useSelector((state: IState) => state.forms.classifications[classificationId]);
    const views = classification && classification?.surveyData;
    const viewIndex = useSelector((state: IState) => state.forms.control.viewIndex);
    const analysis = useSelector((state: IState) => state.forms.currentAnalysis);
    const majorType = classification && classification.majorType;
    // console.log("VIEWS",views);
    // console.log("ANALYSIS",analysis);

    const [showError, sShowError] = useState<boolean>(false);

    const analysisResponse = useMemo(() => {
        let analysisResponse;
        switch(majorType) {
            case 'AHVVA':
                analysisResponse = AnalysisResponse(analysis?.response);
                break;
            case 'TERA':
                analysisResponse = AnalysisResponseTERA(analysis?.response);
                break;
            default:
                analysisResponse = AnalysisResponse(analysis?.response);
                break;
        }
        return analysisResponse;
    }, [analysis]);
    // console.log("ANALYSIS RESPONSE", analysisResponse);

    const viewData = useMemo(() => {
        return views && views[viewIndex] || null;
    }, [views, viewIndex]);
    // console.log("PAGE DATA", viewData);

    // Form checking
    const required = useMemo(() => {
        if (!viewData) return [];
        return viewData?.components.map(component =>
            typeof component.required === 'boolean' ? component.required : component.required === 'true'
        );
    }, [viewData]);
    const totalPaths = useMemo(() => {
        if (!analysis || !classification) return null;
        // console.log("ANALYSIS", analysis);
        let checkPathCount = majorType === "AHVVA";
        if (!checkPathCount) return null;

        let manualPaths = 0, hybridPaths = 0, autoTargets = 0, vehicleCount = 0, topResults = 0;

        if (views) { // legacy support vehicle count
            Object.values(views).forEach(view => {
                const title = view.title.view.data.default;
                if (title && typeof title === 'string' && title.startsWith("Vehicle"))
                    vehicleCount++;
            })
        }
        if (analysis.response) {
            analysis.response.map.targets.forEach(target => {
                if (Object.keys(target.single).length > 0) autoTargets++;
                else if (target.user_specified.length > 0) manualPaths++;
                else if (target.sequence.length > 0) hybridPaths++;
            });
            topResults = analysis.response.top_results;
            if (analysis.response.selected_vehicles) vehicleCount = analysis.response.selected_vehicles.length;
        }

        const totalManualPaths = manualPaths * vehicleCount;
        const totalHybridPaths = hybridPaths * vehicleCount * topResults;
        const totalAutoPaths = autoTargets * vehicleCount * topResults;
        const total = totalManualPaths + totalHybridPaths + totalAutoPaths;
        // console.log("TOTAL:", totalManualPaths, totalHybridPaths, totalAutoPaths, total);

        return { totalManualPaths, totalHybridPaths, totalAutoPaths, total};
    }, [analysis, views]);
    const answered = useMemo(() => {
        if (!viewData) return [];
        return viewData?.components.map(component => {
            const answer = analysisResponse.getData(component.key);
            if (typeof answer === 'number') return true;
            if (component.key === 'map') {
                // console.log("MAP ANSWER",answer);
                if (answer && answer.targets.length > 0) return true;
                else return false;
            }
            if (component.key === 'project_name' || component.key === "Q3") { // Disallow braces
                if (answer && (answer.includes('{') || answer.includes('}'))) return false;
                else return !!answer;
            }
            return !!answer;
        });
    }, [viewData, analysisResponse]);
    const visibility = useMemo(() => {
        if (!viewData) return [];
        return viewData?.components.map(component => {
            if (typeof component.visibility === 'string' && majorType && analysis) {
                if (component.visibility === 'true') return true;
                else if (component.visibility === 'false') return false;
                const parsed = parseFormula(component.visibility, majorType, analysis);
                // console.log("",parsed);
                return parsed;
            }
            return component.visibility
        });
    }, [viewData, analysis, majorType]);
    const minRemDistConverted = useMemo(() => {
        if (!classification) return 0;
        let isImperial = checkImperial(classification);
        if (isImperial) return Math.ceil(MIN_REMAINING_DISTANCE*3.281);
        else return MIN_REMAINING_DISTANCE;
    }, [analysis, classification]);
    const maxAhvvaPaths = useMemo(() => {
        return viewData && viewData.max_ahvva_paths || MAX_AHVVA_PATHS;
    }, [viewData]);
    const viewErrors = useMemo(() => {
        if (!analysis ||!analysis.response) return [];
        const errors: { text: string, severity: string }[] = [];

        if (majorType === "AHVVA") {
            if (viewData?.title.view.data.default === "Area of Analysis" && totalPaths !== null) {
                const { totalManualPaths, totalHybridPaths, totalAutoPaths, total } = totalPaths;
                if (total > maxAhvvaPaths) {
                    errors.push({
                        text: `
                            Total = Manual + (Hybrid * Number of vehicles * Number of results) + (Auto * Number of vehicles * Number of results)
                        `,
                        severity: 'severity-warning'
                    }, {
                        text: `
                            Too many paths! The maximum total paths is ${maxAhvvaPaths}. You have ${total}.
                        `,
                        severity: 'severity-danger'
                    });
                }
                if (!analysis.response.selected_vehicles || analysis.response.selected_vehicles.length < 1) {
                    errors.push({
                        text: `Please select at least one vehicle.`,
                        severity: 'severity-danger'
                    });
                }
                if (!analysis.response.remaining_distance || analysis.response.remaining_distance <= minRemDistConverted) {
                    errors.push({
                        text: `Path distance limit must be greater than ${minRemDistConverted}.`,
                        severity: 'severity-danger'
                    })
                }
            }
        };
        // console.log(errors)

        return errors;
    }, [totalPaths, viewData, majorType, minRemDistConverted, analysis]);
    const error = useMemo(() => {
        const error = [];
        for (let i = 0; i < required.length; i++) {
            if (required[i]) {
                if (typeof visibility[i] === 'boolean' && !visibility[i]) {
                    error.push(false);
                    continue;
                }
                if (!answered[i]) {
                    error.push(true);
                    continue;
                }
            }
            error.push(false);
        }
        return error;
    }, [required, answered]);
    const pageRequiredAnswered = useMemo(() => {
        if (!viewData) return true;
        // console.log("requiredList", required);
        // console.log("answeredList", answered);
        // console.log("visibilityList", visibility);
        // console.log("errorList", error);

        if (viewErrors.length > 0) return false;
        for (let i = 0; i < error.length; i++) {
            if (error[i]) return false;
        }
        return true;
    }, [error]);
    // console.log("CAN MOVE NEXT PAGE REQUIRED ANSWERED",pageRequiredAnswered);

    const componentSelector = (
        format: string,
        dataKey: string,
        viewData: ViewData,
        section: ComponentSection,
        required: boolean | string,
        questionText?: any,
    ) => {
        const requiredBool = typeof required === 'boolean' ? required : required === 'true';
        switch(format) {
            case "row_text":
                return (
                    <RowText
                        text={viewData.default?.toString() || ""}
                        title={section === "title"}
                    />
                );
            case "single_line_text":
                if (
                    dataKey === "user_email" // AHVVA email list
                ) return (
                    <EmailListInput
                        dataKey={dataKey}
                        defaultValue={viewData.default?.toString() || ""}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
                if (
                    dataKey === "Q11" // TERA email contact
                    || dataKey === "Q2" // TERA self email
                ) return (
                    <EmailSingleInput
                        dataKey={dataKey}
                        defaultValue={viewData.default?.toString() || ""}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
                return (
                    <SingleLineInput
                        dataKey={dataKey}
                        defaultValue={viewData.default?.toString() || ""}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "number_input":
                return (
                    <NumberInput
                        dataKey={dataKey}
                        defaultValue={viewData.default?.toString() || ""}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "free_text":
                return (
                    <FreeTextInput
                        dataKey={dataKey}
                        defaultValue={viewData.default?.toString() || ""}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "multiple_choice":
                return (
                    <MultipleChoiceInput
                        dataKey={dataKey}
                        defaultValue={viewData.default || []}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "single_choice":
                return (
                    <SingleChoiceInput
                        dataKey={dataKey}
                        defaultValue={viewData.default || []}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "calendar":
                return (
                    <Calendar
                        dataKey={dataKey}
                        defaultValue={viewData.default || []}
                        questionText={questionText}
                        required={requiredBool}
                    />
                );
            case "custom_map":
                return (
                    <Map
                        dataKey={dataKey}
                        questionText={questionText}
                    />
                );
            case "drawable_map":
                return (
                    <DrawableMap />
                );
            default:
                <Box></Box>
        }
    }

    const submitForm = () => {
        // dispatch(formsActions.updateFormResponse('status', 1 )); // Set status INPUT_RECEIVED
        dispatch(formsActionsAsync.submitAnalysis.request({ status: 1 }));
        navigate("/app");
    }

    const updateIndex = (index: number) => {
        dispatch(formsActions.updateControl({ viewIndex: index }));
    }

    const isVisible = (visibility?: string | boolean) => {
        // console.log("VISIBILITY STRING", visibility);
        if (typeof visibility === 'boolean') return visibility;
        if (visibility === "true") return true;
        else if (visibility === "false") return false;
        else if (visibility === undefined) return true;
        // visibility is a conditional if string
        const re = /\$\{\w*\}/g;
        const matched = re.exec(visibility) || "";
        const dataKey = matched[0].replace("${", "").replace("}", "");
        const dataValue = analysisResponse.getData(dataKey);
        if (
            Array.isArray(dataValue)
            && dataValue.length === 2
            && visibility.includes(dataValue[0])
            && visibility.includes(dataValue[1])
        ) return true;
        return false;
    }

    useEffect(() => {
        updateIndex(0); // Start at initial page
    }, []);


    return (
        <Box
            sx={{
                width: '65%',
                marginLeft: '30px',
                marginBottom: '20px',
                '.error': {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    marginBottom: '10px',
                    '> p': {
                        marginLeft: '5px'
                    }
                },
                '.severity-warning': {
                    backgroundColor: 'rgba(255, 111, 0, 0.1)',
                    color: "rgba(255, 140, 0, 1)"
                },
                '.severity-danger': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                    color: "#B00020"
                },
            }}
        >
            <Box sx={{
                marginBottom: '30px'
            }}>
                <FormTitle
                    title={viewData && viewData.title.view.data.default?.toString() || ""}
                    description={viewData && viewData.title.description.default?.toString() || ""}
                />
            </Box>
            <Box>
                {viewData && viewData.components.map(
                    (component: Component, i: number) => {
                        const key = component.key;
                        const answerData = component.views.answer;
                        const questionData = component.views.question;
                        const visibility = component.visibility;
                        const required = component.required;
                        // if (!visibility) return (<Box key={key}></Box>);
                        if (!isVisible(visibility)) return (<Box key={key}></Box>);
                        return (
                            <Box
                                key={`component-${key}`}
                                sx={{
                                    marginBottom: '30px'
                                }}
                            >
                                <Box sx={{
                                    marginBottom: '15px'
                                }}>
                                    {componentSelector(
                                        questionData.view.format,
                                        key,
                                        questionData.view.data,
                                        "question",
                                        required,
                                        questionData.view.data.default
                                    )}
                                    <Typography variant="body2" sx={{ color: "#9c9c9c" }}>
                                        {questionData.description.default}
                                    </Typography>
                                    {showError && error[i] &&
                                        <Box className='error severity-danger'>
                                            <ErrorOutlineIcon />
                                            <Typography>
                                                Please provide a valid answer
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box>
                                    {componentSelector(
                                        answerData.view.format,
                                        key,
                                        answerData.view.data,
                                        "answer",
                                        required,
                                        questionData.view.data.default
                                    )}
                                    <Typography variant="body2" sx={{ color: "#9c9c9c" }}>
                                        {answerData.description.default}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    }
                )}
            </Box>
            <Box sx={{ marginBottom: '30px' }}>
                {viewErrors.length > 0 && viewErrors.map((error, i) => 
                    <Box key={`view-error-${i}`} className={`error ${error.severity}`}>
                        <ErrorOutlineIcon />
                        <Typography>
                            {error.text}
                        </Typography>
                    </Box>
                )}
            </Box>
            {views && viewIndex === Object.keys(views).length-1 &&
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                        <Typography variant="body1">
                            All steps completed
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            sx={{ width: '100%' }}
                            variant="contained"
                            onClick={() => submitForm()}
                        >
                            <Typography
                                variant="body2"
                                sx={{ marginLeft: '10px' }}
                            >
                                Submit
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            }
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                width: 'inherit',
                zIndex: 1000,
                backgroundColor: '#fbfbfb',
                marginLeft: '-30px'
            }}>
            <Divider sx={{ marginTop: '15px', marginBottom: '15px' }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Button
                    sx={{
                        visibility: views && viewIndex === 0 ? 'hidden' : 'visible'
                    }}
                    variant="outlined"
                    onClick={() => updateIndex(viewIndex-1)}
                >
                    <ArrowBackIcon fontSize="small" />
                    <Typography
                        variant="body2"
                        sx={{ marginLeft: '10px' }}
                    >
                        Back
                    </Typography>
                </Button>
                <Button
                    sx={{
                        visibility: views && viewIndex === Object.keys(views).length-1 ? 'hidden' : 'visible'
                    }}
                    variant="outlined"
                    onClick={() => {
                        if (pageRequiredAnswered) {
                            updateIndex(viewIndex+1);
                            sShowError(false);
                        } else {
                            sShowError(true);
                        }
                        typeof window !== 'undefined' && window.scrollTo(0, 0);
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{ marginRight: '10px' }}
                    >
                        Next
                    </Typography>
                    <ArrowForwardIcon fontSize="small" />
                </Button>
            </Box>
            </Box>
        </Box>
    );
}

export default FormContent;