import * as React from 'react'
import { Router, RouteComponentProps } from "@reach/router";
import DashboardLayout from './DashboardLayout';
import Overview from "./Overview";
import Clients from "./Clients/Clients";
import Users from "./Users/Users";
import Licences from './Licences/Licences';
import Forms from './ReportInput/Forms';
import Views from "./ReportView/Views";
import Store from './Store/Store';
import AccountDetails from "./Users/AccountDetails";
import { useDirectLink } from './Hooks/useDirectLink';


const Dashboard: React.FC<RouteComponentProps> = () => {
    useDirectLink();

    return (
        <DashboardLayout>
            <Router>
                <Overview default path="/" />
                <Clients path="/clients/*" />
                <Users path="/users/*" />
                <Licences path="/licences/*" />
                <Store path="/store/*" />
                <Forms path="/forms/*" />
                <Views path="/views/*" />
                <AccountDetails path="/account" />
            </Router>
        </DashboardLayout>
    );
}

export default Dashboard;